<template>
  <div>
    <template v-if="cep.id && current_user && current_user.role=='admin'">
      <div class="row">
        <div class="col-12">
          <div class="form-box-wrapper black" :class="expanded_boxes.includes('import') ? 'expanded' : ''">
            <span class="expand-btn" @click="toggleExpandBox('import')"></span>
            <div class="heading">
              <div class="row align-items-center">
                <div class="col-12">
                  <p><strong>0.0</strong> Importa JSON <br><span>Funzione abilitata solo agli admin</span></p>
                </div>
              </div>
            </div>
            <template v-if="expanded_boxes.includes('import')">
              <hr>
              <div class="value-row">
                <div class="row align-items-center">
                  <div class="col-12">
                    <textarea id="ctm_json" name="ctm_json" v-model="ctm_json"></textarea>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-box-wrapper black" :class="expanded_boxes.includes('upload') ? 'expanded' : ''">
            <span class="expand-btn" @click="toggleExpandBox('upload')"></span>
            <div class="heading">
              <div class="row align-items-center">
                <div class="col-12">
                  <p><strong>0.0</strong> Carica file <br><span>Funzione abilitata solo agli admin</span></p>
                </div>
              </div>
            </div>
            <template v-if="expanded_boxes.includes('upload')">
              <hr>
              <div class="value-row">
                <div class="row align-items-center">
                  <div class="col-12">
                    <div class="form-wrapper">
                      <label for="quote_file">Sostituisci il documento di conferma ricevuto dal cliente (PDF)</label>
                      <CInputFile id="motivation" name="motivation" placeholder="..." accept="application/pdf" v-model="upload_data.quote_file"></CInputFile>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="upload-btn-wrapper">
                      <span class="upload-btn" :class="upload_btn_class" @click="uploadFile()">Carica file</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
    <div class="row">
      <div class="col-12">
        <p class="section-ot">Informazioni generali <span class="edit-btn" @click="showEditCepGeneralInfo()"></span></p>
        <div class="form-box-wrapper grey">
          <div class="row">
            <div class="col-12 col-md-8">
              <template v-if="cep_data.scenery_id">
                <div class="col-12">
                  <div class="heading">
                    <div class="row align-items-center">
                      <div class="col-12">
                        <p>Scenario</p>
                      </div>
                    </div>
                  </div>
                  <div class="value-row">
                    <div class="row">
                      <div class="col-12 col-md-4">
                        <label for="project_code">Codice</label>
                        <span class="visual-value">{{$findObjInArray(sceneries, 'id', cep_data.scenery_id).code}}</span>
                      </div>
                      <div class="col-12 col-md-8">
                        <label for="project_code">Descrizione</label>
                        <span class="visual-value">{{$findObjInArray(sceneries, 'id', cep_data.scenery_id).desc}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div class="heading" :class="cep_data.scenery_id ? 'wmt' : ''">
                <div class="row align-items-center">
                  <div class="col-12">
                    <p>Gestione progetto</p>
                  </div>
                </div>
              </div>
              <div class="value-row">
                <div class="row">
                  <template v-if="!cep_data.scenery_id || (cep_data.scenery_id && $findObjInArray(sceneries, 'id', cep_data.scenery_id).ea_visible)">
                    <div class="col-12 col-md-4">
                      <label for="ea_id">EA</label>
                      <template v-if="cep_data.ea_id && $findObjInArray(eas, 'value', cep_data.ea_id)">
                        <span class="visual-value">{{$findObjInArray(eas, 'value', cep_data.ea_id).localized_label}}</span>
                      </template>
                      <template v-else>
                        <span class="visual-value">-</span>
                      </template>
                    </div>
                  </template>
                  <template v-if="!cep_data.scenery_id || (cep_data.scenery_id && $findObjInArray(sceneries, 'id', cep_data.scenery_id).pl_visible)">
                    <div class="col-12 col-md-4">
                      <label for="pl_id">PL</label>
                      <template v-if="cep_data.pl_id && $findObjInArray(pls, 'value', cep_data.pl_id)">
                        <span class="visual-value">{{$findObjInArray(pls, 'value', cep_data.pl_id).localized_label}}</span>
                      </template>
                      <template v-else>
                        <span class="visual-value">-</span>
                      </template>
                    </div>
                  </template>
                  <template v-if="!cep_data.scenery_id || (cep_data.scenery_id && $findObjInArray(sceneries, 'id', cep_data.scenery_id).pc_visible)">
                    <div class="col-12 col-md-4">
                      <label for="pc_id">PC</label>
                      <template v-if="cep_data.pc_id && $findObjInArray(pcs, 'value', cep_data.pc_id)">
                        <span class="visual-value">{{$findObjInArray(pcs, 'value', cep_data.pc_id).localized_label}}</span>
                      </template>
                      <template v-else>
                        <span class="visual-value">-</span>
                      </template>
                    </div>
                  </template>
                </div>
              </div>
              <div class="heading wmt">
                <div class="row align-items-center">
                  <div class="col-12">
                    <p>Altre informazioni</p>
                  </div>
                </div>
              </div>
              <div class="value-row">
                <div class="row">
                  <div class="col-12 col-md-4">
                    <label for="status">Stato</label>
                    <span class="visual-value status" :class="cep_data.status">{{$getEnumLabel(cep_statuses, cep_data.status)}}</span>
                  </div>
                  <div class="col-12 col-md-4">
                    <label for="interloc">Livello interlocutore</label>
                    <span class="visual-value">{{$getEnumLabel(cep_interlocs, cep_data.interloc)}}</span>
                  </div>
                </div>
              </div>
              <template v-if="quote_file || cep.motivation">
                <div class="heading wmt">
                  <div class="row align-items-center">
                    <div class="col-12">
                      <p>Documenti e motivazione</p>
                    </div>
                  </div>
                </div>
                <div class="value-row">
                  <div class="row">
                    <template v-if="quote_file">
                      <div class="col-12 col-md-4">
                        <label for="file_url">Documento allegato</label>
                        <span class="visual-value oe"><a :href="quote_file.file_url" target="_blank">{{quote_file.file_name}}</a></span>
                      </div>
                    </template>
                    <template v-if="cep.motivation">
                      <div class="col-12 col-md-4">
                        <label for="motivation">Motivazione</label>
                        <span class="visual-value">{{cep.motivation}}</span>
                      </div>
                    </template>
                  </div>
                </div>
              </template>
            </div>
            <div class="col-12 col-md-4">
              <div class="ctm-heading">
                <div class="row align-items-center">
                  <div class="col-12">
                    <p>Informazioni cliente</p>
                  </div>
                </div>
              </div>
              <div class="value-row">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <label for="name">Nome cliente</label>
                    <span class="visual-value">{{$niceObjField($findObjInArray(clients, 'id', cep_data.client_id), 'name')}}</span>
                  </div>
                  <div class="col-12 col-md-6">
                    <label for="code">Codice</label>
                    <span class="visual-value">{{$niceObjField($findObjInArray(clients, 'id', cep_data.client_id), 'code')}}</span>
                  </div>
                </div>
              </div>
              <template v-if="cep_bill_info">
                <div class="ctm-heading">
                  <div class="row align-items-center">
                    <div class="col-12">
                      <p class="itm">Informazioni di fatturazione</p>
                    </div>
                  </div>
                </div>
                <div class="value-row">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <label for="name">Denominazione azienda</label>
                      <span class="visual-value">{{$niceObjField(cep_bill_info, 'business_name')}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <label for="vat_code">P.Iva</label>
                      <span class="visual-value">{{$niceObjField(cep_bill_info, 'vat_code')}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <label for="address">Indirizzo</label>
                      <span class="visual-value">{{$niceObjField(cep_bill_info, 'address')}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <label for="sdi">SDI</label>
                      <span class="visual-value">{{$niceObjField(cep_bill_info, 'sdi')}}</span>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="cep_contact">
                <div class="ctm-heading">
                  <div class="row align-items-center">
                    <div class="col-12">
                      <p class="itm">Informazioni contatto</p>
                    </div>
                  </div>
                </div>
                <div class="value-row">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <label for="name">Nome</label>
                      <span class="visual-value">{{$niceObjField(cep_contact, 'name')}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <label for="telephone">Telefono</label>
                      <span class="visual-value">{{$niceObjField(cep_contact, 'telephone')}}</span>
                    </div>
                    <div class="col-12">
                      <label for="email">E-mail</label>
                      <span class="visual-value">{{$niceObjField(cep_contact, 'email')}}</span>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="edit-general-info-wrapper" v-if="edit_cep_general_info">
      <cep-form-general-info
        rails_env="rails_env"
        :cep="cep"
        :users="users"
        :sceneries="sceneries"
        :clients="clients"
        :contacts="contacts"
        :bill_infos="bill_infos"
        :cep_statuses="cep_statuses"
        :cep_interlocs="cep_interlocs"
        :project_codes="project_codes"
        @hideEditCepGeneralInfo="hideEditCepGeneralInfo"
      ></cep-form-general-info>
    </div>
  </div>
</template>

<script>
  import CSelect from "../form/CSelect.vue"
  import CInput from "../form/CInput.vue"
  import CTextarea from "../form/CTextarea.vue"
  import CInputDate from "../form/CInputDate.vue"
  import CInputFile from "../form/CInputFile.vue"

  import * as helperFunctions from "../../helpers/helpers"

  export default {
    components:{
      CSelect,
      CInput,
      CTextarea,
      CInputDate,
      CInputFile,
    },

    props: {
      rails_env: String,
      current_user: Object,
      cep: Object,
      quote_file: Object,
      users: Array,
      sceneries: Array,
      clients: Array,
      contacts: Array,
      bill_infos: Array,
      project_codes: Array,
      cep_statuses: Array,
      cep_interlocs: Array,
      cep_contact: Object,
      cep_bill_info: Object,
    },

    data(){
      return {
        ctm_json: null,

        expanded_boxes: [],

        cep_data: {
          scenery_id: this.cep.scenery_id,
          ea_id: this.cep.ea_id,
          pl_id: this.cep.pl_id,
          pc_id: this.cep.pc_id,
          client_id: this.cep.client_id,
          project_code: this.cep.project_code,
          status: this.cep.status,
          interloc: this.cep.interloc,
        },

        creating_client: false,

        client_data: {
          name: null,
          code: null,
          vat_code: null,
          address: null,
          sdi: null,
        },

        btn_class: '',

        upload_data: {
          quote_file: null,
        },

        upload_btn_class: '',

        edit_cep_general_info: false,
      }
    },

    watch: {
      ctm_json: function(){
        if(this.ctm_json){
          var parsed_json = JSON.parse(this.ctm_json);
          var keys = [
            'ea_id',
            'pl_id',
            'pc_id',
            'client_id',
            'project_code',
            'status',
            'interloc',
          ];

          for(var i=0;i<keys.length;i++){
            var key = keys[i];
            switch(key){
              case 'status':
                this.cep_data[key] = this.$getEnumValue(this.cep_statuses, parsed_json.in[key]);
                break;
              default:
                this.cep_data[key] = parsed_json.in[key];
                break;
            }
          }

          this.$emit('ctmJson', this.ctm_json);
        }
      },
    },

    computed: {
      eas: function(){
        return this.users.filter((item) => { return item.is_ea; }).map((item) => { return {label: this.$niceUserFullName(item), value: item.id, localized_label: this.$niceUserFullName(item)}; });
      },
      pls: function(){
        return this.users.filter((item) => { return item.is_pl; }).map((item) => { return {label: this.$niceUserFullName(item), value: item.id, localized_label: this.$niceUserFullName(item)}; });
      },
      pcs: function(){
        return this.users.filter((item) => { return item.is_pc; }).map((item) => { return {label: this.$niceUserFullName(item), value: item.id, localized_label: this.$niceUserFullName(item)}; });
      },
    },

    methods: {
      ...helperFunctions,

      toggleExpandBox(box){
        var index = this.expanded_boxes.indexOf(box);
        if(index>=0){
          this.expanded_boxes.splice(index, 1);
        }
        else{
          this.expanded_boxes.push(box);
        }
      },

      showEditCepGeneralInfo(){
        this.edit_cep_general_info = true;
      },

      hideEditCepGeneralInfo(){
        this.edit_cep_general_info = false;
      },

      uploadFile(){
        this.upload_btn_class = 'loading';
        var params = new FormData();
        if(this.upload_data.quote_file){
          params.append('cep[quote_file]', this.upload_data.quote_file);
        }
        this.axios.put('/api/ceps/'+this.cep.id+'/upload_file', params, {headers: {'Content-Type': 'multipart/form-data'}}).then(response => {
          var obj = response.data;
          if(obj.data.success){
            window.location.href = '/ceps/'+obj.data.cep_id;
          }
          else{
            this.upload_btn_class = '';
            console.log(obj);
          }
        });
      },
    }
  }
</script>
