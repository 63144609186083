<template>
  <div>
    <new-cep-form
      rails_env="rails_env"
      :cep="cep"
      :cep_td="cep_td"
      :users="users"
      :sceneries="sceneries"
      :clients="clients"
      :contacts="contacts"
      :bill_infos="bill_infos"
      :cep_statuses="cep_statuses"
      :cep_interlocs="cep_interlocs"
      :project_codes="project_codes"
    ></new-cep-form>
  </div>
</template>

<script>
  export default {
    props: {
      rails_env: String,
      cep: Object,
      cep_td: Object,
      users: Array,
      sceneries: Array,
      clients: Array,
      contacts: Array,
      bill_infos: Array,
      cep_statuses: Array,
      cep_interlocs: Array,
      project_codes: Array,
    },

    data(){
      return {

      }
    },

    methods: {

    }
  }
</script>